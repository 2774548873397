import verified from "@assets/verified.png";
import {
  Box,
  VStack,
  Image,
  HStack,
  Text,
  // useDisclosure,
} from "@chakra-ui/react";
import useHotspotStore from "@stores/hotspot";
import dayjs from "dayjs";
import { Headset, ArrowLeft, Users, Milestone } from "lucide-react";
import React, { useEffect } from "react";

import AcceptInviteButton from "@/buttons/AcceptInviteButton";
// import DeclineInviteButton from "@/buttons/DeclineInviteButton";
import InterestedButton from "@/buttons/InterestedButton";
import JoinWaitlistButton from "@/buttons/JoinWaitlistButton";
// import DeclineInviteModal from "@/modals/DeclineInviteModal";
import { whatsappLink } from "@/config";

interface HotspotInfoProps {
  setStage: (stage: number) => void;
  showWaitlistButton?: boolean;
  showInterestedButton?: boolean;
}

function formatDateString(dateString: string): string {
  const date = dayjs(dateString);
  const day = date.format("D");
  const month = date.format("MMM");
  const year = date.format("YY");
  return `${day} ${month}'${year}`;
}

const formatTime = (dateString: string): string => {
  const date = dayjs(dateString);
  return date.format("hh:mm A");
};

const HotspotInfo: React.FC<HotspotInfoProps> = ({
  setStage,
  showWaitlistButton = false,
  showInterestedButton = false,
}) => {
  const hotspot = useHotspotStore((state) => state.hotspot);
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [boxHeight, setBoxHeight] = React.useState(0);
  const [boxWidth, setBoxWidth] = React.useState(0);
  const numberOfBlurDivs = 30;
  const heightDelta = boxHeight / numberOfBlurDivs;
  const blurDivs = Array(numberOfBlurDivs).fill(null);
  // const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === boxRef.current) {
          setBoxHeight(entry.contentRect.height);
          setBoxWidth(entry.contentRect.width);
        }
      }
    });

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    setTimeout(() => {
      if (boxRef.current) {
        setBoxHeight(boxRef.current.clientHeight);
        setBoxWidth(boxRef.current.clientWidth);
      }
    }, 100);

    const cleanUpNode = boxRef.current;
    return () => {
      if (cleanUpNode) {
        resizeObserver.unobserve(cleanUpNode);
      }
    };
  }, []);
  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="#101010"
      overflowY="auto"
      sx={{ scrollbarWidth: "none" }}
    >
      <VStack
        spacing="1rem"
        // pb={showWaitlistButton ? "6.5rem" : "10rem"}
        pb="6.5rem"
      >
        <VStack
          bgImage={hotspot?.banner_image_url}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          borderBottomLeftRadius="0.75rem"
          borderBottomRightRadius="0.75rem"
          w="100%"
          h="25rem"
          justifyContent="space-between"
          position="relative"
        >
          <HStack
            px="1rem"
            justifyContent="space-between"
            w="100%"
            position="absolute"
            top="1.5rem"
          >
            <Box
              w="2rem"
              h="2rem"
              borderRadius="0.5rem"
              background="#10101080"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => setStage(0)}
            >
              <ArrowLeft color="white" size={20} />
            </Box>
            <Box
              w="2rem"
              h="2rem"
              borderRadius="0.5rem"
              background="#10101080"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Headset
                size={20}
                color="white"
                onClick={() => window.open(whatsappLink, "_blank")}
              />
            </Box>
          </HStack>
          {blurDivs.map((_, index) => (
            <Box
              key={index}
              position="absolute"
              height={`${boxHeight - index * heightDelta}px`}
              w={boxWidth}
              bottom="0"
              backgroundColor="rgba(16,16,16,0.1)"
              backdropFilter="auto"
              borderRadius="0.75rem"
              backdropBlur="1px"
              zIndex={19 - index}
            />
          ))}
          <Box ref={boxRef} position="absolute" bottom="0" w="100%" zIndex={20}>
            <VStack spacing="0.5rem" px="1rem" pb="1.25rem" pt="1.25rem">
              <HStack
                spacing="0.75rem"
                alignContent="center"
                justifyContent="center"
              >
                <Text fontWeight="400" color="white">
                  {hotspot?.experience?.tagline}
                </Text>
                <Box
                  background="#10101080"
                  borderRadius="0.5rem"
                  display="flex"
                  justifyContent="center"
                  alignItems={"center"}
                  py="0.25rem"
                  px="0.5rem"
                >
                  <Users size={12} color="white" />
                  <Text fontWeight="400" color="white" fontSize="xs" ml={1}>
                    {hotspot?.capacity}
                  </Text>
                </Box>
              </HStack>
              <Text
                fontFamily="titan"
                color="white"
                fontSize="2rem"
                textAlign="center"
              >
                {hotspot?.name}
              </Text>
            </VStack>
          </Box>
        </VStack>
        <VStack px="1rem" spacing="1rem" w="100%">
          <HStack spacing="1rem" justifyContent="space-around" w="100%">
            <VStack
              background="b3"
              flex="1"
              height="6.5rem"
              borderRadius="0.5rem"
              alignItems="flex-start"
              pt="1rem"
              pl="1rem"
            >
              <Text color="#FFFFFF52" fontSize="10px">
                Hosted by
              </Text>
              <HStack spacing="1px">
                <Text color="white" fontSize="sm">
                  {hotspot?.host.user.name}
                </Text>
                <Image src={verified} boxSize="12px" ml="2px" />
              </HStack>
            </VStack>
            <Image
              src={hotspot?.host.user.profile_image_url}
              w="6.5rem"
              h="6.5rem"
              borderRadius="0.5rem"
              objectFit={"cover"}
            />
          </HStack>
          <HStack spacing="1rem" justifyContent="space-around" w="100%">
            <VStack
              w="50%"
              h="6.5rem"
              background="b3"
              borderRadius="0.5rem"
              flex="1"
              alignItems="flex-start"
              p="1rem"
              spacing="0.25rem"
            >
              <Text color="#FFFFFF52" fontSize="10px">
                Date
              </Text>
              <Text color="white" fontSize="sm" fontWeight="700" mt="0.25rem">
                {formatDateString(hotspot?.start_datetime ?? "")}
              </Text>
              <Text color="#FFFFFF52" fontSize="sm">
                {dayjs(hotspot?.start_datetime ?? "").format("dddd")}
              </Text>
            </VStack>
            <VStack
              w="50%"
              h="6.5rem"
              background="b3"
              borderRadius="0.5rem"
              flex="1"
              alignItems="flex-start"
              p="1rem"
              spacing="0.25rem"
            >
              <Text color="#FFFFFF52" fontSize="10px">
                Time
              </Text>
              <Text color="white" fontSize="sm" fontWeight="700" mt="0.25rem">
                {formatTime(hotspot?.start_datetime ?? "")}
              </Text>
              <Text color="#FFFFFF52" fontSize="sm">
                Onwards
              </Text>
            </VStack>
          </HStack>
          <VStack
            w="100%"
            h="auto"
            background="b3"
            borderRadius="0.5rem"
            alignItems="flex-start"
            p="1rem"
            mt="0.25rem"
            position="relative"
            onClick={() => {
              window.open(
                `https://maps.google.com/?q=${hotspot?.address.latitude}`,
                "_blank"
              );
            }}
          >
            <Text color="#FFFFFF52" fontSize="10px">
              Venue
            </Text>
            <Text color="white" fontSize="sm" mt="0.25rem">
              {hotspot?.address.address_line_1}
            </Text>
            <Text color="#FFFFFF52" fontSize="sm">
              {hotspot?.address.city}, {hotspot?.address.state},{" "}
              {hotspot?.address.country}
            </Text>
            <Box position="absolute" top="38%" right="1rem">
              <Milestone size="20px" color="white" />
            </Box>
          </VStack>
          <VStack
            w="100%"
            h="auto"
            background="b3"
            borderRadius="0.5rem"
            alignItems="flex-start"
            p="1rem"
            spacing="0.75rem"
          >
            <Text color="#FFFFFF52" fontSize="10px">
              About the Hotspot
            </Text>
            <Text color="#FFFFFFB8">{hotspot?.description}</Text>
          </VStack>
        </VStack>
      </VStack>
      <Box
        w="100%"
        h="auto"
        position="fixed"
        bottom="0"
        background="b2"
        backdropFilter="auto"
        backdropBlur="4px"
        pt="1.25rem"
        pb="1rem"
      >
        {showWaitlistButton ? (
          <JoinWaitlistButton onClick={() => setStage(2)} />
        ) : showInterestedButton ? (
          <InterestedButton onClick={() => setStage(2)} isFullWidth={false} />
        ) : (
          <>
            <AcceptInviteButton
              onClick={() => setStage(2)}
              isFullWidth={false}
            />
            {/* <DeclineInviteButton onClick={onOpen} /> */}
          </>
        )}
      </Box>

      {/* {!showWaitlistButton && (
        <DeclineInviteModal isOpen={isOpen} onClose={onClose} />
      )} */}
    </Box>
  );
};

export default HotspotInfo;
