import { extendTheme } from "@chakra-ui/react";
import "@fontsource/space-grotesk";
import "@fontsource/titan-one";

const theme = extendTheme({
  colors: {
    b2: "rgba(16, 16, 16, 0.7)",
    formHeading: "#FFFFFF7A",
    placeholder: "#FFFFFF29",
    purple: "#9196FF",
    b3: "#FFFFFF0D"
  },
  fonts: {
    body: `'Space Grotesk', sans-serif`,
    heading: `'Space Grotesk', sans-serif`,
    titan: `'Titan One', cursive`,
  },
  fontFamilies: {
    titan: `'Titan One', cursive`,
  },
  gradients: {
    silver:
      "radial-gradient(96.63% 295.53% at 0% 8.04%, rgba(34, 34, 34, 0.4) 0%, rgba(153, 153, 153, 0.4) 49.87%, rgba(34, 34, 34, 0.4) 100%)",
  },
  borders: {
    blue: "1px solid #9196FF",
    grey: "1px solid #FFFFFF14",
  },
});

export default theme;
