import logo from "@assets/logo.png";
import ticket from "@assets/Ticket.svg";
import verified from "@assets/verified.png";
import {
  Container,
  HStack,
  Image,
  Text,
  VStack,
  Flex,
  Divider,
} from "@chakra-ui/react";
import useHotspotStore from "@stores/hotspot";
import dayjs from "dayjs";
import { CalendarCheck, Timer } from "lucide-react";
import React from "react";

function formatDateString(dateString: string): string {
  const date = dayjs(dateString);
  const day = date.format("D");
  const month = date.format("MMM");
  const year = date.format("YY");
  return `${day} ${month}'${year}`;
}

const formatTime = (dateString: string): string => {
  const date = dayjs(dateString);
  return date.format("hh:mmA");
};

interface TicketProps {
  setState: (state: number) => void;
  data: any;
}

const Ticket: React.FC<TicketProps> = ({ setState, data }) => {
  const hotspot = useHotspotStore((state) => state.hotspot);
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Container
      ref={containerRef}
      p={0}
      position="relative"
      backgroundImage={ticket}
      minH="600px"
      mx="1rem"
      w="auto"
      backgroundSize="cover"
      backgroundPosition="center"
      onClick={() => setState(1)}
    >
      <Image
        src={logo}
        alt="logo"
        position="absolute"
        top="3.5rem"
        left="50%"
        transform="translateX(-50%)"
      />
      <VStack
        justifyContent={"center"}
        alignItems={"center"}
        w="100%"
        gap={2}
        pb="4.75rem"
      >
        <Image
          src={hotspot?.experience.icon_url}
          alt="icon"
          boxSize="7.5rem"
          mt="6.3rem"
        />
        <Text
          fontSize="3xl"
          fontWeight="700"
          color="white"
          textAlign="center"
          mx="2rem"
          wordBreak="break-word"
        >
          {hotspot?.name}
        </Text>

        <HStack gap={0}>
          <Text fontSize="md" color="formHeading">
            by
          </Text>
          <Text fontSize="md" color="white" ml={1}>
            {hotspot?.host.user.name}
          </Text>
          <Image src={verified} boxSize="16px" ml={"4px"} />
        </HStack>

        <HStack mt="1rem">
          <Flex alignItems="center">
            <CalendarCheck size={12} color="white" />
            <Text fontWeight="400" color="white" ml={1} fontSize="sm">
              {formatDateString(hotspot?.start_datetime ?? "")}
            </Text>
            <Text color="#FFFFFF52" fontSize="sm" ml={1}>
              {dayjs(hotspot?.start_datetime ?? "").format("dddd")}
            </Text>
          </Flex>
          <Divider orientation="vertical" height="12px" borderColor="white" />
          <Flex alignItems="center">
            <Timer size={12} color="white" />
            <Text color="white" ml={1} fontSize="sm">
              {formatTime(hotspot?.start_datetime ?? "")}
            </Text>
            <Text color="#FFFFFF52" fontSize="sm" ml={1}>
              onwards
            </Text>
          </Flex>
        </HStack>
        {data.items.length > 2 && (
          <HStack
            w="100%"
            backgroundColor="rgba(16,16,16,0.5)"
            h="4rem"
            backdropFilter="auto"
            backdropBlur="4px"
            pl="1rem"
            mt="1.75rem"
          >
            <HStack ml="1rem">
              {data.items.slice(0, 3).map((item: any, index: number) => (
                <Image
                  key={index}
                  src={item.invitee.profile_image_url}
                  boxSize={7}
                  borderRadius="full"
                  ml={index !== 0 ? "-4" : "0"}
                />
              ))}
            </HStack>
            <Text fontSize="sm" color="white">
              You, {data.items[0].invitee.name} + {data.total - 2} accepted the invite!
            </Text>
          </HStack>
        )}
      </VStack>
      <Text
        fontSize="sm"
        color="white"
        textAlign="center"
        left="50%"
        transform="translateX(-50%)"
        position="absolute"
        bottom="2.5rem"
      >
        You are a host favourite!
      </Text>
    </Container>
  );
};

export default Ticket;
