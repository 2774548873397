import IAsset from "@interfaces/asset";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import axiosInstance from "./axiosInstance";

const createAsset = async (asset: IAsset) => {
  try {
    const body = {
      assets: [asset],
    };
    const res = await axiosInstance.post("/users/assets", body);
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

const uploadToPresignedUrl = async ({
  url,
  file,
}: {
  url: string;
  file: File;
}) => {
  try {
    const res = await axios.put(url, file, {
      headers: {
        "Content-Type": "image/jpeg",
        "Content-Length": file.size.toString(),
      },
    });
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    throw e;
  }
};

const markAssetAsUploaded = async (assetId: string) => {
  try {
    await axiosInstance.post(`/users/assets/${assetId}/uploaded`);
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

export const useCreateAsset = () => {
  const mutation = useMutation({
    mutationKey: ["createAsset"],
    mutationFn: (asset: IAsset) => createAsset(asset),
  });
  return mutation;
};

export const useUploadToPresignedUrl = () => {
  const mutation = useMutation({
    mutationKey: ["uploadToPresignedUrl"],
    mutationFn: ({ url, file }: { url: string; file: File }) =>
      uploadToPresignedUrl({ url, file }),
  });
  return mutation;
};

export const useMarkAssetAsUploaded = () => {
  const mutation = useMutation({
    mutationKey: ["markAssetAsUploaded"],
    mutationFn: (assetId: string) => markAssetAsUploaded(assetId),
  });
  return mutation;
};
