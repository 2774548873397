import logo from "@assets/logo.png";
import bgImage from "@assets/Ticket.png";
import verified from "@assets/verified.png";
import { Box, HStack, Image, VStack, Text } from "@chakra-ui/react";
import useHotspotStore from "@stores/hotspot";
import { Headset, ArrowLeft } from "lucide-react";
import React from "react";

import { whatsappLink } from "@/config";

interface HalfTicketProps {
  setState: (state: number) => void;
}

const HalfTicket: React.FC<HalfTicketProps> = ({ setState }) => {
  const hotspot = useHotspotStore((state) => state.hotspot);
  return (
    <Box
      bgImage={bgImage}
      mx="1rem"
      minH="328px"
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <HStack justifyContent="space-between" w="100%" px={1} pt="1.5rem">
        <Box
          w="2rem"
          h="2rem"
          borderRadius="0.5rem"
          background="#10101080"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => setState(0)}
        >
          <ArrowLeft color="white" size={20} />
        </Box>
        <Image src={logo} alt="logo" />
        <Box
          w="2rem"
          h="2rem"
          borderRadius="0.5rem"
          background="#10101080"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Headset
            size={20}
            color="white"
            onClick={() => window.open(whatsappLink, "_blank")}
          />
        </Box>
      </HStack>
      <VStack
        justifyContent={"center"}
        alignItems={"center"}
        w="100%"
        gap={2}
        pb="3rem"
      >
        <Image
          src={hotspot?.experience.icon_url}
          alt="icon"
          boxSize="7.5rem"
          mt="2rem"
        />
        <Text
          fontSize="3xl"
          fontWeight="700"
          color="white"
          textAlign="center"
          mx="2rem"
          wordBreak="break-word"
        >
          {hotspot?.name}
        </Text>
        <HStack gap={0}>
          <Text fontSize="md" color="formHeading">
            by
          </Text>
          <Text fontSize="md" color="white" ml={1}>
            {hotspot?.host.user.name}
          </Text>
          <Image src={verified} boxSize="16px" ml={"4px"} />
        </HStack>
      </VStack>
    </Box>
  );
};

export default HalfTicket;
