"use client";
import { ChakraProvider } from "@chakra-ui/react";
import DownloadRedirect from "@pages/DownloadRedirect"; 
import Invite from "@pages/Invite";
import Share from "@pages/Share";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Error from "@views/Error";
import { AxiosError } from "axios";
import React from "react";
import "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router-dom";

import theme from "./theme";

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: AxiosError;
  }
}

const queryClient = new QueryClient();

const ExternalRedirect: React.FC = () => {
  window.location.href = "https://www.8club.co";
  return null;
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <div className="app-container">
          <ErrorBoundary fallback={<Error />}>
            <Routes>
              <Route path="/invite*" element={<Invite />} />
              <Route path="/share/:code" element={<Share />} />
              <Route path="/download" element={<DownloadRedirect />} />
              <Route path="/error" element={<Error />} />
              <Route path="/*" element={<ExternalRedirect />} />
            </Routes>
          </ErrorBoundary>
        </div>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
