import Spin from "@components/Spinner";
import useFetchHotspot from "@hooks/useFetchHotspot";
import useFetchUser from "@hooks/useFetchUser";
import { useErrorStore } from "@stores/error";
import useUserStore from "@stores/user";
import Auth from "@views/Auth";
import Error from "@views/Error";
import HotspotInfo from "@views/HotspotInfo";
import HotspotLanding from "@views/HotspotLanding";
import OnboardingForm from "@views/OnboardingForm";
import Waitlist from "@views/Waitlist";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { WaitlistStatus } from "@/interfaces/user";

const Share: React.FC = () => {
  const { code } = useParams() as { code: string };
  const { isLoading, isError,} = useFetchHotspot(code);
  const { setIsAPIError, setError, isAPIError } = useErrorStore();
  const { isError: userError } = useFetchUser();
  const [stage, setStage] = useState(0);
  const user = useUserStore((state) => state.user);
  const showWaitlistButton = !user || user.status === WaitlistStatus.DRAFT;
  const showInterestedButton = !showWaitlistButton;

  useEffect(() => {
    if (isError) {
      setError("Error", "Failed to fetch hotspot");
      setIsAPIError(true);
    }
  }, [isError, setError, setIsAPIError]);

  useEffect(() => {
    if (userError) {
      setError("Error", "Failed to fetch user");
      setIsAPIError(true);
    }
  }, [userError, setError, setIsAPIError]);

  if (isAPIError) {
    return <Error />;
  }
  if (isLoading) {
    return <Spin />;
  }

  switch (stage) {
    case 0:
      return <HotspotLanding setStage={setStage} showInterestedButton={true} code={code} />;
    case 1:
      return (
        <HotspotInfo
          setStage={setStage}
          showWaitlistButton={showWaitlistButton}
          showInterestedButton={showInterestedButton}
        />
      );
    case 2:
      return <Auth setGlobalStage={setStage}/>;
    case 3:
      return <OnboardingForm setStage={setStage}/>;
    case 4:
      // if (user?.status == WaitlistStatus.ACCEPTED) {
      //   return (
      //     <Box
      //       w="100%"
      //       h="100%"
      //       backgroundColor="#101010"
      //       overflowY="auto"
      //       sx={{ scrollbarWidth: "none" }}
      //     >
      //       <Header />
      //       <HotspotCard showInterestedButton />
      //       <Box
      //         m="1rem"
      //         background="#FFFFFF05"
      //         borderTopLeftRadius="1rem"
      //         borderTopRightRadius="1rem"
      //         mb="0"
      //       >
      //         <HStack px="1rem" py="1.25rem" justifyContent="space-between">
      //           <Text fontWeight="700" color="white" ml={1} fontSize="sm">
      //             View past Hotspots @8club
      //           </Text>
      //           <ChevronRight size="24px" color="#FFFFFF52" />
      //         </HStack>
      //       </Box>
      //     </Box>
      //   );
      // }
      return <Waitlist code={code}/>;
  }
};

export default Share;
