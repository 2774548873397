import placeholder from "@assets/image_placeholder.png";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  Image,
  Center,
  HStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import { UserGender } from "@interfaces/user";
import useUserStore from "@stores/user";
import { Camera } from "lucide-react";
import React, { useState } from "react";

import { useGetAllExperiences } from "@/api/experience";

const genderOptions = [
  { value: UserGender.Male, label: "Male" },
  { value: UserGender.Female, label: "Female" },
  { value: UserGender["Non Binary"], label: "Non-binary" },
];

const isValidDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date instanceof Date &&
    !isNaN(date.getTime()) &&
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    calculateAge(dateString ?? "") >= 13 &&
    calculateAge(dateString ?? "") <= 100
  );
};

const calculateAge = (dateOfBirth: string): number => {
  if (!dateOfBirth) return 0;
  const birthDate = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

interface FormProps {
  setFormValid: (valid: boolean) => void;
}

const Form: React.FC<FormProps> = ({ setFormValid }) => {
  const { user, setUser, photo, setPhoto, instaHandle, setInstaHandle, setInterestedExperiences, } =
    useUserStore((state) => ({
      user: state.user,
      setUser: state.setUser,
      photo: state.photo,
      setPhoto: state.setPhoto,
      instaHandle: state.instaHandle,
      setInstaHandle: state.setInstaHandle,
      setInterestedExperiences: state.setInterestedExperiences,
    }));
  const [dateError, setDateError] = useState<boolean>(false);

  const { data: experiences } = useGetAllExperiences(); // Fetch all experiences

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setUser({ ...user, date_of_birth: date });
    if (isValidDate(date)) {
      setDateError(false);
    } else {
      setDateError(true);
    }
  };

  const selected = [...(user?.interested_experiences || [])];
  // function to handle interested experience selection
  const handleExperienceSelect = (experienceId: number) => {
    if (selected.includes(experienceId)) {
      // Remove the experience
      const updatedExperiences = selected.filter(id => id !== experienceId);
      setInterestedExperiences(updatedExperiences);
    } else {
      // Add the experience
      setInterestedExperiences([...selected, experienceId]);
    } 
  };

  const isFormValid =
    !!user?.name &&
    !!user?.date_of_birth &&
    !!user?.gender &&
    !!(photo || user.profile_image_url) &&
    !!instaHandle &&
    (user?.interested_experiences|| []).length > 0 &&
    isValidDate(user?.date_of_birth);

  React.useEffect(() => {
    setFormValid(isFormValid);
  }, [isFormValid, setFormValid]);

  return (
    <Box p="1rem" color="white" pb={isFormValid ? "0.5rem" : "2rem"}>
      <Text fontSize="2xl" mt="1rem" fontWeight="700">
        Let's get started
      </Text>
      <VStack spacing={4} align="stretch" mt="1rem">
        <FormControl>
          <HStack align="center" mb={4} spacing={14}>
            <FormLabel fontSize="sm" color="formHeading" fontWeight="400">
              Upload your photo
            </FormLabel>
            <Box
              position="relative"
              boxSize="6rem"
              borderRadius="full"
              overflow="hidden"
            >
              {user?.profile_image_url ? (
                <Image
                  src={user.profile_image_url}
                  boxSize="100%"
                  objectFit="cover"
                />
              ) : photo ? (
                <Image
                  src={photo as string}
                  alt="Uploaded Photo"
                  boxSize="100%"
                  objectFit="cover"
                />
              ) : (
                <Center boxSize="100%" color="white" bgImage={placeholder}>
                  <Center
                    boxSize="50%"
                    bg="#10101080"
                    borderRadius="full"
                    backdropFilter="auto"
                    backdropBlur="8px"
                  >
                    <Camera size={24} />
                  </Center>
                </Center>
              )}
              {!user?.profile_image_url && (
                <Input
                  type="file"
                  accept="image/*"
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  opacity="0"
                  cursor="pointer"
                  onChange={handlePhotoUpload}
                />
              )}
            </Box>
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="sm" color="formHeading" fontWeight="400">
            01 What do we call you?
          </FormLabel>
          <Input
            placeholder="Your name"
            value={user?.name ?? ""}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            _placeholder={{ color: "placeholder" }}
            border="grey"
            _focus={{ border: "blue" }}
            fontSize="md"
            p={6}
            bg="b3"
          />
        </FormControl>
        <FormControl isInvalid={!!dateError}>
          <FormLabel fontSize="sm" color="formHeading">
            02 What's your birth date?
          </FormLabel>
          <Input
            type="date"
            value={user?.date_of_birth ?? ""}
            onChange={handleDateChange}
            color={user?.date_of_birth ? "white" : "placeholder"}
            border="grey"
            _focus={{ border: "blue" }}
            fontSize="md"
            p={6}
            bg="b3"
          />
          {dateError && (
            <FormErrorMessage color="red" fontSize="sm">
              Please enter a valid date
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl>
          <FormLabel fontSize="sm" color="formHeading">
            03 Who do you identify yourself as?
          </FormLabel>
          <Flex gap={2} justify="space-around">
            {genderOptions.map((g) => (
              <Button
                key={g.value}
                onClick={() => setUser({ ...user, gender: g.value })}
                variant={user?.gender === g.value ? "solid" : "outline"}
                border={user?.gender === g.value ? "blue" : "grey"}
                background={"b3"}
                width="auto"
                color={user?.gender === g.value ? "purple" : "placeholder"}
                px="2rem"
                py="1.5rem"
                isActive={user?.gender === g.value}
                _active={{ background: "#9196FF2D" }}
                _hover={{ background: "#9196FF2D" }}
              >
                {g.label}
              </Button>
            ))}
          </Flex>
        </FormControl>
        <FormControl>
            <FormLabel fontSize="sm" color="formHeading">
              04 What types of hotspots interests you?
            </FormLabel>
            <Flex gap={2} flexWrap="wrap">
              {experiences?.map((experience: any) => (
                <Button
                  key={experience.id}
                  background={"b3"}
                  width="auto"
                  px="1rem"
                  py="1.5rem"
                  variant={selected?.includes(experience.id) ? "solid" : "outline"}
                  border={selected?.includes(experience.id) ? "blue" : "grey"}
                  _active={{ background: "#9196FF2D" }}
                  _hover={{ background: "#9196FF2D" }}
                  color={selected?.includes(experience.id) ? "purple" : "placeholder"}
                  isActive={selected?.includes(experience.id)}
                  onClick={() => handleExperienceSelect(experience.id)}
                  flexGrow={1}
                >
                  {experience.name}
                </Button>
              ))}
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" color="formHeading">
            05 What's your instagram handle?
          </FormLabel>
          <Input
            placeholder="ranook.xd"
            value={instaHandle}
            onChange={(e) => setInstaHandle(e.target.value)}
            _placeholder={{ color: "placeholder" }}
            border="grey"
            _focus={{ border: "blue" }}
            fontSize="md"
            p={6}
            bg="b3"
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

export default Form;
