import image from "@assets/AppInvite.png";
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import React from "react";

import DownloadAppButton from "@/buttons/DownloadAppButton";

interface AppInviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AppInviteModal: React.FC<AppInviteModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay backdropFilter="auto" backdropBlur="16px" />
      <ModalContent
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        background="#FFFFFF0D"
        backdropFilter="auto"
        backdropBlur={"40px"}
      >
        <ModalCloseButton color="white"/>
        <ModalBody alignItems="center" justifyContent="center">
          <Image src={image} mt="2rem" ml={0.5}/>
        </ModalBody>
        <ModalFooter px="0.7rem">
          <DownloadAppButton />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AppInviteModal;
