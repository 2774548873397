import Spin from "@components/Spinner";
import useFetchHotspotInvite from "@hooks/useFetchHotspotInvite";
import useFetchUser from "@hooks/useFetchUser";
import { WaitlistStatus } from "@interfaces/user";
import { useErrorStore } from "@stores/error";
import useHotspotStore from "@stores/hotspot";
import useUserStore from "@stores/user";
import Auth from "@views/Auth";
import Error from "@views/Error";
import HotspotInfo from "@views/HotspotInfo";
import HotspotLanding from "@views/HotspotLanding";
import InviteAccept from "@views/InviteAccept";
import OnboardingForm from "@views/OnboardingForm";
import Waitlist from "@views/Waitlist";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Invite: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") as string;
  const { isLoading, isError } = useFetchHotspotInvite(code);

  const [stage, setStage] = useState(0);
  const validity = useHotspotStore((state) => state.validity);
  const user = useUserStore((state) => state.user);
  const { isError: userError } = useFetchUser();
  const showInterestedButton = isError || !validity;
  const showWaitlistButton = !user || user.status === WaitlistStatus.DRAFT;
  const { setError, isAPIError, setIsAPIError } = useErrorStore();

  useEffect(() => {
    if (userError) {
      setError("Error", "Failed to fetch user");
      setIsAPIError(true);
    }
  }, [userError, setError, setIsAPIError]);

  useEffect(() => {
    if (isError) {
      setError("Error", "Failed to fetch hotspot");
      setIsAPIError(true);
    }
  }, [isError, setError, setIsAPIError]);

  if (isLoading) {
    return <Spin />;
  } else if (isAPIError) {
    return <Error />;
  }
  switch (stage) {
    case 0:
      return (
        <HotspotLanding
          setStage={setStage}
          showInterestedButton={showInterestedButton}
          code={code}
        />
      );
    case 1:
      return (
        <HotspotInfo
          setStage={setStage}
          showInterestedButton={showInterestedButton}
          showWaitlistButton={showWaitlistButton}
        />
      );
    case 2:
      return <Auth setGlobalStage={setStage} />;
    case 3:
      return <OnboardingForm setStage={setStage} />;
    case 4:
      return <InviteAccept />;
    case 5:
      return <Waitlist code={code} />;
  }
};

export default Invite;
