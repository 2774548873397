import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import axiosInstance from "./axiosInstance";

const getOTP = async (phone: string) => {
  try {
    const body = {
      phone_number: phone,
      country_code: "+91",
    };
    const res = await axiosInstance.post("/users/generate-otp", body);
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

const submitOTP = async (phone: string, otp: string) => {
  try {
    const body = {
      phone_number: phone,
      country_code: "+91",
      otp,
    };
    const res = await axiosInstance.post("/users/verify-otp", body);
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

export const useGetOTP = (phone: string) => {
  const mutation = useMutation({
    mutationKey: ["getOtp", phone],
    mutationFn: () => getOTP(phone),
  });
  return mutation;
};

export const useSubmitOTP = () => {
  const mutation = useMutation({
    mutationKey: ["otp"],
    mutationFn: ({ phone, otp }: { phone: string; otp: string }) => submitOTP(phone, otp),
  });
  return mutation;
};
