import { useGetGuestList } from "@api/hotspot";
import verified from "@assets/verified.png";
import waitlistCard1 from "@assets/waitlist_card_1.png";
import waitlistCard2 from "@assets/waitlist_card_2.png";
import waitlistCard3 from "@assets/waitlist_card_3.png";
import {
  Box,
  Image,
  VStack,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import HalfTicket from "@components/HalfTicket";
import Header from "@components/Header";
import Spin from "@components/Spinner";
import Ticket from "@components/Ticket";
import WaitlistCard from "@components/WaitlistCard";
import { WaitlistStatus } from "@interfaces/user";
import { useErrorStore } from "@stores/error";
import useHotspotStore from "@stores/hotspot";
import useInviteStore from "@stores/invite";
import useUserStore from "@stores/user";
import Error from "@views/Error";
import dayjs from "dayjs";
import { Milestone } from "lucide-react";
import React, { useEffect } from "react";

import DownloadAppButton from "@/buttons/DownloadAppButton";
import InviteFriendButton from "@/buttons/InviteFriendButton";
import AppInviteModal from "@/modals/AppInviteModal";
function formatDateString(dateString: string): string {
  const date = dayjs(dateString);
  const day = date.format("D");
  const month = date.format("MMM");
  const year = date.format("YY");
  return `${day} ${month}'${year}`;
}

const formatTime = (dateString: string): string => {
  const date = dayjs(dateString);
  return date.format("hh:mm A");
};

const InviteAccept: React.FC = () => {
  const user = useUserStore((state) => state.user);
  const hotspot = useHotspotStore((state) => state.hotspot);
  const invite = useInviteStore((state) => state.invite);
  const [state, setState] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, isError } = useGetGuestList(hotspot?.id ?? 0);
  const { setError, setIsAPIError, isAPIError } = useErrorStore();

  useEffect(() => {
    if (isError) {
      setError("Error", "Failed to fetch user");
      setIsAPIError(true);
    }
  });
  if (isLoading) return <Spin />;
  if (isAPIError) return <Error />;
  if (!invite.is_free) return <div>Not free hotspot</div>
  if (!state)
    return (
      <Box
        w="100%"
        h="100%"
        backgroundColor="#101010"
        overflowY="auto"
        sx={{
          scrollbarWidth: "none",
        }}
      >
        <Header />
        <Ticket setState={setState} data={data} />
        <VStack mx="1rem" spacing="1rem" pb="6rem" mt="1rem">
          {user?.status == WaitlistStatus.WAITLIST && <WaitlistCard />}
          <Image src={waitlistCard1} background="b2" />
          <Image src={waitlistCard2} background="b2" />
          <Image src={waitlistCard3} background="b2" />
        </VStack>
        <Box
          w="100%"
          h="auto"
          position="fixed"
          bottom="0"
          background="#FFFFFF05"
          backdropFilter="auto"
          backdropBlur="8px"
          pt="1.25rem"
          pb="1rem"
        >
          <DownloadAppButton isFullWidth={false} />
        </Box>
      </Box>
    );

  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="#101010"
      overflowY="auto"
      sx={{
        scrollbarWidth: "none",
      }}
    >
      <HalfTicket setState={setState} />
      <VStack px="1rem" spacing="1rem" w="100%" py="1rem" mb="6rem">
        <HStack spacing="1rem" justifyContent="space-around" w="100%">
          <VStack
            background="#FFFFFF0D"
            flex="1"
            height="6.5rem"
            borderRadius="0.5rem"
            alignItems="flex-start"
            pt="1rem"
            pl="1rem"
          >
            <Text color="#FFFFFF52" fontSize="10px">
              Hosted by
            </Text>
            <HStack spacing="1px">
              <Text color="white" fontSize="sm">
                {hotspot?.host.user.name}
              </Text>
              <Image src={verified} boxSize="12px" ml="2px" />
            </HStack>
          </VStack>
          <Image
            src={hotspot?.host.user.profile_image_url}
            w="6.5rem"
            h="6.5rem"
            objectFit="cover"
            borderRadius="0.5rem"
            sx={{
              "overflowClipMargin": "unset",
            }}
          />
        </HStack>
        <HStack spacing="1rem" justifyContent="space-around" w="100%">
          <VStack
            w="50%"
            h="6.5rem"
            background="b3"
            borderRadius="0.5rem"
            flex="1"
            alignItems="flex-start"
            p="1rem"
            spacing="0.25rem"
          >
            <Text color="#FFFFFF52" fontSize="10px">
              Date
            </Text>
            <Text color="white" fontSize="sm" fontWeight="700" mt="0.25rem">
              {formatDateString(hotspot?.start_datetime ?? "")}
            </Text>
            <Text color="#FFFFFF52" fontSize="sm">
              {dayjs(hotspot?.start_datetime ?? "").format("dddd")}
            </Text>
          </VStack>
          <VStack
            w="50%"
            h="6.5rem"
            background="b3"
            borderRadius="0.5rem"
            flex="1"
            alignItems="flex-start"
            p="1rem"
            spacing="0.25rem"
          >
            <Text color="#FFFFFF52" fontSize="10px">
              Time
            </Text>
            <Text color="white" fontSize="sm" fontWeight="700" mt="0.25rem">
              {formatTime(hotspot?.start_datetime ?? "")}
            </Text>
            <Text color="#FFFFFF52" fontSize="sm">
              Onwards
            </Text>
          </VStack>
        </HStack>
        <VStack
          w="100%"
          h="auto"
          background="b3"
          borderRadius="0.5rem"
          alignItems="flex-start"
          p="1rem"
          mt="0.25rem"
          position="relative"
          onClick={() => {
            window.open(
              `https://maps.google.com/?q=${hotspot?.address.latitude}, ${hotspot?.address.longitude}`,
              "_blank"
            );
          }}
        >
          <Text color="#FFFFFF52" fontSize="10px">
            Venue
          </Text>
          <Text color="white" fontSize="sm" mt="0.25rem">
            {hotspot?.address.address_line_1}
          </Text>
          <Text color="#FFFFFF52" fontSize="sm">
            {hotspot?.address.city}, {hotspot?.address.state},{" "}
            {hotspot?.address.country}
          </Text>
          <Box position="absolute" top="38%" right="1rem">
            <Milestone size="20px" color="white" />
          </Box>
        </VStack>
        <VStack
          w="100%"
          h="auto"
          background="b3"
          borderRadius="0.5rem"
          alignItems="flex-start"
          p="1rem"
          spacing="0.75rem"
        >
          <Text color="#FFFFFF52" fontSize="10px">
            About the Hotspot
          </Text>
          <Text color="#FFFFFFB8">{hotspot?.description}</Text>
        </VStack>
      </VStack>
      <Box
        w="100%"
        h="auto"
        position="fixed"
        bottom="0"
        background="b2"
        backdropFilter="auto"
        backdropBlur="6px"
        pt="1.25rem"
        pb="1rem"
      >
        <InviteFriendButton isFullWidth={false} onClick={onOpen} />
      </Box>
      <AppInviteModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default InviteAccept;
