import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { SiAppstore } from "react-icons/si";

import { appStoreLink } from "@/config";

interface DownloadAppButtonProps {
  isFullWidth?: boolean;
}
const DownloadAppButton: React.FC<DownloadAppButtonProps> = ({
  isFullWidth = true,
}) => {
  // const isiOS = () =>
  //   /iPad|iPhone|iPod/.test(navigator.userAgent) ||
  //   (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  const onClick = () => {
    // if (isiOS()) {
    //   window.location.href = "https://apps.apple.com/in/app/8club-get-invited-to-hotspots/id6547866574";
    // } else {
    //   window.location.href =
    //     "https://play.google.com/store/apps/details?id=ie.zappy.fennec.eightmembersclub&hl=en_IN";
    // }
    window.location.href = appStoreLink;
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      w={isFullWidth ? "100%" : "auto"}
      mx="1rem"
    >
      <Button
        colorScheme="#ffffff"
        width="full"
        height="3.5rem"
        bgGradient="silver"
        border="1px solid rgba(128, 128, 128, 0.5)"
        background="rgba(16, 16, 16, 0.9)"
        borderRadius="0.5rem"
        leftIcon={<SiAppstore size={16} />}
        onClick={onClick}
      >
        Download The App
      </Button>
    </Box>
  );
};

export default DownloadAppButton;
